@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: #465ff1;
    --primary-foreground: 210 40% 98%;

    --secondary: #eeeff0;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
@layer components {
  .text-12 {
    @apply text-[12px];
  }
  .text-14 {
    @apply text-[12px] xl:text-[14px];
  }
  .text-16 {
    @apply text-[14px] xl:text-[16px];
  }
  .text-18 {
    @apply text-[16px] xl:text-[18px];
  }
  .text-20 {
    @apply text-[18px] xl:text-[20px];
  }
  .text-25 {
    @apply text-[25px] xl:text-[25px];
  }
  .text-30 {
    @apply text-[25px] xl:text-[30px];
  }
  .text-36 {
    @apply text-[30px] xl:text-[36px];
  }
  .text-40 {
    @apply text-[30px] xl:text-[40px];
  }
  .text-50 {
    @apply text-[40px] xl:text-[50px];
  }
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body {
  /* direction: rtl; */
}

.PhoneInput {
  background-color: #f8f9fa;
  border-radius: 10px;
  flex-direction: row-reverse;
}
.PhoneInputInput {
  background-color: #f8f9fa;
  border-radius: 10px;
  height: 50px;
  /* direction: rtl; */
  border: none;
  padding: 0 10px;
  outline: none;
  padding: 0 10px;
  /* background-color: red; */
  text-align: end;
}
.PhoneInputCountry {
  gap: 10px;
  padding: 0 10px;
}

.custom-content {
  width: 100%;
  overflow-x: auto;
  padding: 1.5rem;
}

.custom-content figure.table {
  margin-inline: auto;
}

.custom-content table {
  border-collapse: separate;
  border-spacing: 8px;
  background-color: #fff;
}

.custom-content th,
.custom-content td {
  padding: 1rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.custom-content tr:nth-child(1) td {
  font-size: 15px;
  color: #666;
  background-color: #eeeff0;
}

.custom-content tr:not(:nth-child(1)) td {
  font-size: 15px;
  color: #666;
  background-color: #f8f9fa;
  border: 1px solid #ebebeb;
}

@media (max-width: 768px) {
  .custom-content th,
  .custom-content td {
    padding: 0.5rem;
    font-size: 14px;
  }
}

.outline-none {
  width: 100% !important;
}

.watermark {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 9999;
}
